<template>
  <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Etiketten</h4>
                            <p>Liste aller Etiketten</p>
                        </div>

                        <div class="card-tools">
                            <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                <ul class="navbar-nav">  
                                    <li class="nav-item mr-1">
                                        <div class="input-group input-group-sm" style="margin-top: 0;">
                                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadLabels" :value="search" @input="$store.commit('labels/changeSearch', $event.target.value)" />
                                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadLabels">
                                                <i class="fas fa-fw fa-search"></i>
                                            </button>
                                            <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                <i class="fas fa-fw fa-times"></i>
                                            </button>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <button type="button" class="mr-1 btn btn-default btn-sm" @click="loadLabels">
                                            <i class="fas fa-fw fa-sync"></i>
                                        </button>
                                    </li>
                                    <li class="nav-item">
                                        <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('labels.create')">
                                            <i class="fas fa-fw fa-plus-circle"></i> Neues Etikett
                                        </button>
                                    </li>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="card-body">
                        <template v-if="labels.data.length >= 1">
                            <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th><input type="checkbox" v-model="selectAll" /></th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('name')">Name</a>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>
                                        Typ
                                    </th>
                                    <th style="width: 140px;">Aktionen</th>                 
                                </thead>
                                <tbody>
                                    <tr v-for="(label) in labels.data" :key="label.id">
                                        <td><input type="checkbox" :value="label.id" v-model="selectedLabels"/></td>
                                        <td>{{ label.name }}</td>
                                        <td>{{ label.type }}</td>
                                        <td>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'labels.show', params: {id: label.id}}" v-if="$auth.check('labels.show') && !$auth.check('labels.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'labels.details', params: {id: label.id}}" v-if="$auth.check('labels.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteLabel(label.id)" variant="danger" v-if="$auth.check('labels.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </template>

                        <template v-else>
                            <h5>Keine Labels gefunden</h5>
                        </template>
                    </div>

                    <div class="card-footer">
                        <template v-if="labels.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="labels" @pagination-change-page="loadLabels" :limit="3"></pagination>
                                </div>
                                <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ labels.meta.from }} - {{ labels.meta.to }} von {{ labels.meta.total }}</span>
                                </div>
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Artikel</option>
                                        <option value="50">50 Artikel</option>
                                        <option value="75">75 Artikel</option>
                                        <option value="100">100 Artikel</option>
                                        <option value="125">125 Artikel</option>
                                        <option value="150">150 Artikel</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="labelModal" title="Neues Label" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
            <form ref="labelForm" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">

                        <div class="form-group">
                            <label for="name" class="control-label">Name*</label>
                            <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                            <has-error :form="form" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="description" class="control-label">Beschreibung*</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="description"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="distributor" class="control-label">Druckerei*</label>
                            <input v-model="form.distributor" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('distributor')}">
                            <has-error :form="form" field="distributor"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="unit" class="control-label">Bestelleinheit*</label>
                            <select v-model="form.unit" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('unit')}">
                                <option value="Stk">Stück</option>
                                <option value="Rolle">Rolle</option>
                            </select>
                            <has-error :form="form" field="unit"></has-error>
                        </div>


                        <div class="form-group">
                            <label for="unit" class="control-label">Typ*</label>
                            <select v-model="form.type" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}">
                                <option value="product">Produktetikett</option>
                                <option value="flyer">Flyer</option>
                                <option value="other">Sonstiges</option>
                            </select>
                            <has-error :form="form" field="unit"></has-error>
                        </div>
                        

                        <div class="form-group">
                            <label for="logo" class="control-label">Vorschaubild (Druckdatei)</label>
                            <!-- <input v-model="form.logo" type="file" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('logo')}"> -->
                            <b-form-file id="logo" size="sm" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                            <has-error :form="form" field="logo"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>


  </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import {mapState} from 'vuex';


export default {
    name: "Labels",
    title: "Etiketten",
    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
                distributor: "",
                unit: "",
                type: "",
                logo: null,
            }),
            labels: {
                data: [],
            },
            selectedLabels: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: true,
        }
    },

    watch: {
        params: {
            handler () {
                this.loadLabels();
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.labels.data ? this.selectedLabels.length == this.labels.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.labels.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedLabels = selected;
            }
        },
        ...mapState('labels', ['params', 'search', 'page'])
    },


    methods:{
        resetSearch(){
            this.$store.commit('labels/changeSearch', '');
            this.loadLabels(); 
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createLabel();     
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("labelModal");
        },

        createLabel(){
            this.form
                .post("/labels")
                .then(() => {
                    this.loadLabels();
                    this.$bvModal.hide("labelModal");
                    this.$swal({
                        icon: "success",
                        title: "Etikett gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        loadLabels(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/labels", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.labels = response.data;
                    this.$store.commit('labels/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteLabel(id){
            this.$swal({
                    title: "Möchtest du das Etikett wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/labels/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Etikett erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadLabels();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        
    },


    created() {
        this.loadLabels();
    },
    

}
</script>

<style>

</style>